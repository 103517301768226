import React from 'react'
import './style.css'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DatePicker from '../BookingForm/Form-Steps/datepicker'
import { useMutation } from '@apollo/react-hooks'
import { updateDateAndTimeMutation } from './graphql/mutations'
import { navigate } from 'gatsby'
import { toast } from 'react-toastify'

const initialValues = {
	pickupDate: ''
}

const schema = Yup.object().shape({
	pickupDate: Yup.string().when('rideType', {
		is: 'scheduled_ride',
		then: Yup.string().required('Date is required')
	})
})
const UpdateRideDetail = ({ state }) => {
	const { rideId } = state ? state : {}
	const [
		updateDateAndTimeMutationTrigger,
		{ data, loading, error }
	] = useMutation(updateDateAndTimeMutation)
	if (loading) {
		console.log('loading from graphql mutation', loading)
	}
	if (error) {
		toast.error(error.graphQLErrors[0].message.message, {
			autoClose: 1200
		})
	}
	if (data) {
		console.log('data return from graphql updateDateAndTime mutation', data)
		toast.success('Ride Updated Successfully', {
			autoClose: 1200
		})
		setTimeout(() => {
			window.history.back()
		}, 1200)
	}
	const handleUpdate = value => {
		console.log('new selected date is', value.pickupDate)
		updateDateAndTimeMutationTrigger({
			variables: {
				id: rideId,
				data: {
					pickupDate: value.pickupDate
				}
			}
		})
	}
	return (
		<div className='container-fluid d-flex hm-login-bg justify-content-center align-items-center'>
			<div className='updateRideContainer'>
				<Formik
					initialValues={initialValues}
					validationSchema={schema}
					onSubmit={values => handleUpdate(values)}
				>
					{({
						values,
						errors,
						touched,
						setFieldValue,
						handleSubmit,
						isValid
					}) => (
						<>
							<div className='forgotPasswordTextView'>
								<p>Update Ride Date And Time</p>
							</div>
							<Form className='container-fluid'>
								{isValid}
								<div className=''>
									<Field
										name='pickupDate'
										component={DatePicker}
										placeholder='Enter Pick Up Date'
									/>

									<ErrorMessage name='pickupDate'>
										{msg => <p className='text-danger p-1 m-0'>{msg}</p>}
									</ErrorMessage>
								</div>
							</Form>
							<div onClick={handleSubmit} className='updateButtonStyle'>
								<div className='updateButtonText'>
									{loading ? <div className='spinner'></div> : 'Update'}
								</div>
							</div>
						</>
					)}
				</Formik>
			</div>
		</div>
	)
}

export { UpdateRideDetail }
