import React from 'react'
import Layout from '../components/Layout/layout'
import SEO from '../components/SEO/seo'
import { UpdateRideDetail } from '../components/UpdateRideDetail'
const RideDetailUpdate = ({ location }) => {
	const { state = {} } = location
	return (
		<Layout>
			<SEO title='Ride Detail Update' />
			<UpdateRideDetail state={state} />
		</Layout>
	)
}

export default RideDetailUpdate
