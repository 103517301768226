import gql from 'graphql-tag'

export const updateDateAndTimeMutation = gql`
	mutation($id: ID!, $data: updateDateAndTimeInput) {
		updateDateAndTime(id: $id, data: $data) {
			id
			date
			pickupLoc
			destLoc
			custPhone
			custFullName
			rideType
			driverId
			billType
			numberOfPassenger
			totalFare
			status

			pickupDate
			startTime
			endTime
			distance
		}
	}
`
